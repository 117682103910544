import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import {
  ColumnsInit,
  ColumnT,
  LibrariesInit,
  LibraryT,
  UseFileImportContextT,
  workbookT,
} from './useFileImport';
import { LibrarySearchRequestT } from '@utils/useLibrarySearch';
import { APP_CONFIG } from "@app_config/app";
import UploadFile from './UploadFile';
import ImportCompoundSet from './importCompoundSet/ImportCompoundSet';
import ImportTargetPanelNarrow from './importTargetPanel/ImportTargetPanelNarrow';
import ImportTargetPanel from './importTargetPanel/ImportTargetPanel';
import ImportDoseResponse from './importDoseResponse/ImportDoseResponse';
import ImportGeneric from './importGeneric/ImportGeneric';
import { FileImportContext } from '@stores/FileImportContext';
import { useNavigate } from "react-router-dom";
import { UserContext } from "@stores/UserContext";
import AbbvieUpload from '@components/abbvieUpload';
import { getUserSubscriptionType } from "@utils/useUserAppInfo";
import { UploadOptionT } from '@src/type';

const INITIAL_STEP = 1;
const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

// Define file type options only once here
const FileImportOptions = [
  {
    label: 'Compound Set w/ Data',
    value: 'compound_set',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  },
  {
    label: 'Target Panel (Universe) - Wide',
    value: 'target_panel',
    info: 'This wide data format uses one row per compound with targets as columns.',
    subscriptions: ['pilot'],
  },
  {
    label: 'Target Panel (Universe) - Narrow',
    value: 'target_panel_narrow',
    info: 'Narrow format with multiple rows per compound for different targets.',
    subscriptions: ['pilot'],
  },
  {
    label: 'Dose Response Curve',
    value: 'dose_response',
    info: 'Requires [compound ID, sample ID, dose, response]. Multiple dose-response sets per compound.',
    subscriptions: ['pilot'],
  },
  {
    label: 'Generic Plotting Data',
    value: 'generic',
    info: 'Generic data import. All rows/columns are imported as-is.',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  },
  {
    label: 'Abbvie Data',
    value: 'abbvie',
    info: 'Custom format for Abbvie data.',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    system: ['3rnd-abbvie'],
  },
];

const UploadData = () => {
  const [workbook, setWorkbook] = useState<workbookT>();
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const [importer, setImporter] = useState('compound_set');
  const [fileLoaded, setFileLoaded] = useState(false);
  const [activeColumn, setActiveColumn] = useState<number>(null);
  const [columns, setColumns] = useState<ColumnT[]>(ColumnsInit);
  const [libraries, setLibraries] = useState<LibraryT[]>(LibrariesInit);
  const [requests, setRequests] = useState<LibrarySearchRequestT[]>([]);
  
  const { user } = useContext(UserContext);
  const userSubscriptionType = getUserSubscriptionType(user.appInfo);
  const navigate = useNavigate();

  // Filter options based on user subscription
  const uploadOptions: UploadOptionT[] = FileImportOptions.filter((opt) =>
    (!opt.subscriptions || opt.subscriptions.includes(userSubscriptionType)) &&
    (!opt.system || opt.system.includes(APP_CONFIG.system))
  ).map(({ label, value, info }) => ({ label, value, info }));

  const context: UseFileImportContextT = {
    workbook, setWorkbook,
    activeStep, setActiveStep,
    columns, setColumns,
    activeColumn, setActiveColumn,
    importer, setImporter,
    fileLoaded, setFileLoaded,
    libraries, setLibraries,
    requests, setRequests,
  };

  const handleReset = () => {
    if (debug) console.log('UploadData | Reset()');
    context.setFileLoaded(false);
    context.setWorkbook(null);
    context.setActiveColumn(null);
    context.setColumns(ColumnsInit);
    context.setLibraries(LibrariesInit);
    context.setActiveStep(INITIAL_STEP);
    navigate('/import/upload');
  };

  const handleImporterSelect = (value: string) => {
    setImporter(value);
  };

  const validImporters = uploadOptions.map((o) => o.value);
  const showImporterComponent = context.workbook && validImporters.includes(context.importer);

  const renderImporter = () => {
    switch (context.importer) {
      case 'compound_set':
        return <ImportCompoundSet handleReset={handleReset} />;
      case 'target_panel':
        return <ImportTargetPanel handleReset={handleReset} />;
      case 'target_panel_narrow':
        return <ImportTargetPanelNarrow formatType={'narrow'} handleReset={handleReset} />;
      case 'dose_response':
        return <ImportDoseResponse handleReset={handleReset} />;
      case 'generic':
        return <ImportGeneric handleReset={handleReset} />;
      case 'abbvie':
        return <AbbvieUpload />;
      default:
        return null;
    }
  };

  return (
    <FileImportContext.Provider value={context}>
      <PageContainer>
        {!showImporterComponent ? (
          <>
            <ContentWrapper>
              <LeftColumn>
                <SectionTitle>File Type</SectionTitle>
                <OptionList>
                  {uploadOptions.map((option) => {
                    const isActive = importer === option.value;
                    return (
                      <OptionButton
                        key={option.value}
                        isActive={isActive}
                        onClick={() => handleImporterSelect(option.value)}
                        title={option.info || ''}
                      >
                        {option.label}
                      </OptionButton>
                    );
                  })}
                </OptionList>
              </LeftColumn>
              
              <RightColumn>
                <InstructionsTitle>Select file type and then upload your files</InstructionsTitle>
                <UploadContainer>
                  <UploadFile
                    choices={uploadOptions}
                    onChange={(e) => setImporter(e.target.value)}
                  />
                </UploadContainer>
              </RightColumn>
            </ContentWrapper>
          </>
        ) : (
          <ImporterContainer>
            {renderImporter()}
          </ImporterContainer>
        )}
      </PageContainer>
    </FileImportContext.Provider>
  );
};

export default UploadData;

/** Styled Components **/

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  width: 100%;
  color: ${(p) => p.theme.palette.textPrimary};
  overflow-y: auto;
  padding: 20px;
`;

const TopInstructions = styled.p`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 30px auto;
  font-size: 1rem;
  line-height: 1.5;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
`;

const LeftColumn = styled.div`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.4rem;
  color: ${(p) => p.theme.palette.accentPrimary};
  margin-bottom: 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  padding-bottom: 5px;
`;

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionButton = styled.button<{ isActive: boolean }>`
  background: ${({ isActive, theme }) => (isActive ? theme.palette.accentPrimary : 'transparent')};
  color: ${({ isActive, theme }) => (isActive ? theme.palette.textPrimary  : theme.palette.accentSecondary )};
  border: 2px solid ${({ theme }) => theme.palette.accentPrimary};
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition: 
    background 0.3s ease,
    color 0.3s ease,
    transform 0.2s ease,
    box-shadow 0.2s ease;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: ${({ theme }) => theme.palette.accentPrimary};
    color: ${({ theme }) => theme.palette.textPrimary};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(70, 130, 180, 0.5);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Icon indicating selectable option */
  &::after {
    content: '${({ isActive }) => (isActive ? '✔️' : '□')}';
    font-size: 1.2rem;
    margin-left: 10px;
    transition: transform 0.2s ease;
  }
`;
const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const InstructionsTitle = styled.h3`
  font-size: 1.2rem;
  color: ${(p) => p.theme.palette.accentPrimary};
  margin-bottom: 20px;
`;

const UploadContainer = styled.div`
  background: ${(p) => p.theme.palette.backgroundSecondary};
  padding: 20px;
  border: 1px dashed ${(p) => p.theme.palette.accentPrimary};
  border-radius: 10px;
`;

const ImporterContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 0 auto;
`;
